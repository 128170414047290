import { Select } from "@okta/odyssey-react-mui";
import { useTranslation } from "react-i18next"

const AccessLengthSelect = () => {

    const {t} = useTranslation();
    const lengthOfAccessOptions = [
        {
            key: '7',
            text: t('guests-table-cell-access-length', {count: 7}),
            value: 7
          },
          {
            key: '14',
            text: t('guests-table-cell-access-length', {count: 14}),
            value: 14
          },
        {
          key: '30',
          text: t('guests-table-cell-access-length', {count: 30}),
          value: 30
        },
        {
          key: '60',
          text: t('guests-table-cell-access-length', {count: 60}),
          value: 60
        },
        {
          key: '90',
          text: t('guests-table-cell-access-length', {count: 90}),
          value: 90
        },
      ]

    return <Select name="accessLength" defaultValue={30} hint={t('invite-guests-access-hint')} label={t('invite-guests-access-label')} options={lengthOfAccessOptions} />
       
}

export default AccessLengthSelect
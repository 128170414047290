import { Form, TextField, Button, ToastStack, Toast, Dialog } from "@okta/odyssey-react-mui";
import { useState } from "react";
import OpportunitySelect from "./OpportunitySelect";
import { addGuest } from "../../services/DemoAPI";
import { useAuth0 } from "@auth0/auth0-react";
import { useTranslation } from "react-i18next";
import AccessLengthSelect from "./AccessLengthSelect";

const GuestInviteForm = ({open = false, setOpen, guests, setGuests}) => {

  const { getAccessTokenSilently } = useAuth0();
  const {t} = useTranslation();

    const [toast, setToast] = useState(false);
    const [guestData, setGuestData] = useState(null);
    const [opportunity_id, setOpportunity_id] = useState()

    function handleHide(event, reason){
      if (reason === 'clickaway') {
          return;
      }

      setToast(false);
  }

    async function submitGuestInvite({target}) {
      const guest = {name: target.guestEmail.value, email: target.guestEmail.value, opportunityId: opportunity_id?.target.value,  expiresAfter: parseInt(target.accessLength.value)}
      setGuestData(guest);
      addGuest(await getAccessTokenSilently(),guest)
      setGuests([...guests, guest]);
      setOpen(false)
      setOpportunity_id(null)
    }

    return (<><Dialog
      PaperProps={{
          component: 'form',
      }}
      title={t('invite-guests-form-title')}
        isOpen={open}
        onClose={() => {setOpen(false); setOpportunity_id(null)}}>

    <Form name="guest-invite" onSubmit={(event) => {
      event.preventDefault();
          submitGuestInvite(event);
          setToast(true);
        }} method='post' autoCompleteType='on' 
        formActions={<><Button label={t('invite-guests-cancel-button')} variant='tertiary' type='reset' onClick={() => {setOpen(false);setOpportunity_id(null)}} /><Button label={t('invite-guests-submit-button', {count: 1})} variant='primary' type='submit' /></>}>
            <TextField name='guestEmail' label={t('invite-guests-email-label')} hint={t('invite-guests-email-hint')} type='email'/>
            <OpportunitySelect opportunitySelected={setOpportunity_id}/>
            <AccessLengthSelect/>
        </Form>
        </Dialog>
        <ToastStack>
        <Toast
            isVisible={toast}
            autoHideDuration={4000}
            onHide={handleHide}
            role='status'
            severity="info"
            text={t('invite-guests-single-acknowledgement', {guestEmail: guestData?.email})}
        />
    </ToastStack>
    </>
    )
} 

export default GuestInviteForm
import React from 'react';
import { Grid, Container } from 'semantic-ui-react';
import { Box, Button, Typography } from '@okta/odyssey-react-mui';
import './CopyCard.css'

const CopyCard = ({ imageUrl, header, copy, cta, ctaAction, ctaSecondary, ctaSecondaryAction }) => {
  return (
    <Grid className='CopyCard'>
      <Grid.Row>
        <Grid.Column width={8} only='computer' className='CopyCardImageContainer'>
          <img className='CopyCardImage' src={imageUrl} alt={header} />
        </Grid.Column>
        <Grid.Column computer={8} mobile={16} tablet={16} className='CopyTextArea'>
          <Container className='space'>
            {header && <Typography variant='h1' color='white'>{header}</Typography>}
            {copy && <Typography variant='body' color='textSecondary'>{copy}</Typography>}
            <Box sx={{display:'flex', gap: '0.85714286rem', paddingTop: '1.71428571rem'}}>
              {cta && ctaAction &&
                <a target="_blank" rel="noreferrer" href={ctaAction}>
                  <Button label={cta}/>
                </a>
              }
              {ctaSecondaryAction && ctaSecondary &&
                <a target="_blank" rel="noreferrer" href={ctaSecondaryAction}>
                  <Button label={ctaSecondary} variant='secondary'/>
                </a>
              }
            </Box>
          </Container>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default CopyCard;

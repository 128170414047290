import axios from 'axios'
import Config from "../Config";
import { Buffer } from 'buffer';

export async function listGuests(accessToken) {
  var config = {
    method: 'get',
    url: Config.resourceServer.demoAPI + '/guests',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  };

  return axios(config)
}

export async function addGuest(accessToken, guestDetails) {
  var config = {
    method: 'post',
    url: Config.resourceServer.demoAPI + '/guests',
    data: JSON.stringify(guestDetails),
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  };

  return axios(config)
}

export async function removeGuest(accessToken, guestEmail) {
  var config = {
    method: 'delete',
    url: Config.resourceServer.demoAPI + '/guests/' + Buffer.from(guestEmail).toString('base64'),
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  };

  return axios(config)
}
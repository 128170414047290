import React, { useCallback, useEffect, useState } from "react";
import { Button, DataTable, DataTableEmptyState, Status } from "@okta/odyssey-react-mui";
import { AddCircleIcon } from "@okta/odyssey-react-mui/icons";
import { useAuth0 } from "@auth0/auth0-react";
import { TimeAgo } from "../../utils";
import { useOpportunityContext } from "../../context/OpportunityContext";
import { useTranslation } from "react-i18next";
import { ExtendGuestAccessForm } from "../forms";

const RegisteredGuestDataTable = ({helpLink, guests, setGuests, getGuests, onError}) => {

  const {opportunities} = useOpportunityContext()
  const {t} = useTranslation();
  const { isAuthenticated } = useAuth0();
  const [ extendFormOpen, setExtendFormOpen ] = useState(false);
  const [ extendGuests, setExtendGuests ] = useState([]);

function onExtendGuests(guests) {
  setExtendGuests(guests); 
  setExtendFormOpen(true);
}

function rowMenuItems(row) {
  const guest = {
    name: row.getValue('email'),
    email: row.getValue('email'),
    opportunityId: row.getValue('opportunityId'),
    expiresAfter: 30
  }
  return <AddCircleIcon onClick={() => onExtendGuests([guest])} />
}

function bulkActionMenuItems(rows) {
  const guestEmails = Object.keys(rows);
  const guestsToExtend = guestEmails.map((guestEmail) => guests.find((guest) => guest.email === guestEmail));
  return [<Button variant="primary" label={t('guests-table-extend-bulk-label')} onClick={() => onExtendGuests(guestsToExtend)} />]
}

const getData = useCallback(({search,sort}) => {
    return filterData({ data: guests, search, sort });
  },
  [guests],
);

const filterData = ({data, ...args}) => {
  let filteredData = data;
  const { search, sort } = args;

  // Implement text-based query filtering
  if (search) {
    filteredData = filteredData.filter((row) =>
      Object.values(row).some((value) =>
        value.toString().toLowerCase().includes(search.toLowerCase()),
      ),
    );
  }

  // Implement sorting
  if (sort && sort.length > 0) {
    filteredData.sort((a, b) => {
      for (const { id, desc } of sort) {
        const aValue = a[id];
        const bValue = b[id];

        if (aValue < bValue) return desc ? 1 : -1;
        if (aValue > bValue) return desc ? -1 : 1;
      }

      return 0;
    });
  }

  return filteredData;
};

function formatAccessTillDateCell(value) {
  const accessTill = new Date(value);
  if(accessTill < new Date()) {
    return <Status label={TimeAgo.format(accessTill, 'round-minute')} severity='warning' />;
   } else {
    return <Status label={TimeAgo.format(accessTill, 'round-minute')} severity='success' />;
   }
}



useEffect(() => {
  if (isAuthenticated) {
      getGuests()
  } else {
      setGuests([])
  }
}, [getGuests, isAuthenticated, setGuests])

  const columns = [
    {
      accessorKey: "email",
      header: t('guests-table-header-email'),
    },
    {
      Cell: ({ cell }) => opportunities?.find((op) => op.opportunity_id === cell.getValue())?.name || t("guests-table-header-opportunity-none"),
      accessorKey: "opportunityId",
      header: t('guests-table-header-opportunity'),
    },
    {
      Cell: ({ cell }) => formatAccessTillDateCell(cell.getValue()),
      accessorKey: "accessTill",
      header: t('guests-table-header-access-expiry-date'),
    },
    {
      Cell: ({ cell }) => TimeAgo.format(new Date(cell.getValue()), 'round-minute'),
      accessorKey: "registered",
      header: t('guests-table-header-registration-date'),
    },
    {
      Cell: ({ cell }) => TimeAgo.format(new Date(cell.getValue()), 'round-minute'),
      accessorKey: "lastLogin",
      header: t('guests-table-header-last-login-date'),
    },
  ]
  return (
          <>
          <ExtendGuestAccessForm open={extendFormOpen} onClose={() => setExtendFormOpen(false)} extendGuests={extendGuests} onUpdate={getGuests} onError={onError} />
          <DataTable
            hasColumnVisibility
            columns={columns}
            getData={getData}
            hasFilters={false}
            hasPagination={false}
            hasRowSelection
            getRowId={(row) => row.email}
            hasSearch
            hasSorting={false}
            emptyPlaceholder={
              <DataTableEmptyState
                heading={t('guests-table-empty-state-heading')}
                text={<>{t('guests-table-empty-state-text')} {helpLink}</>}
              />
            }
            noResultsPlaceholder={<DataTableEmptyState
              heading={t('guests-table-no-results-heading')}
              text={t('guests-table-no-results-text')}
            />}
            rowActionButtons={rowMenuItems}
            bulkActionMenuItems={bulkActionMenuItems}
          />
          </>
  );
};
export default RegisteredGuestDataTable;

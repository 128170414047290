import React from 'react'
import './footer.css'
import { useTranslation } from 'react-i18next';
import { Box, Container } from '@mui/material';
import { Link } from '@okta/odyssey-react-mui';

const Footer = () => {
    const { t } = useTranslation();
    return (
        <footer className="page-footer brand-color">
        <Container sx={{display:'flex', alignItems:'center !important'}}>
            <Box display="flex"  gap={'2rem'} className='footer-links'>
                {t('copyright')}
                <Link href='https://www.okta.com/privacy-policy/'>Privacy Policy</Link>
                <Link href='https://www.okta.com/terms-of-service/'>Site Terms</Link>
                <button className='ot-sdk-show-settings MuiTypography-root MuiTypography-inherit MuiLink-root MuiLink-underlineAlways css-dr9k8r-MuiTypography-root-MuiLink-root buttonLink'>Cookie Settings</button>
                <Link href='https://www.okta.com/your-privacy-choices/'>Your Privacy Choices</Link>
            </Box>
        </Container>
        </footer>
    );
};
export default Footer;
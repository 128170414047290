import React from "react";
import { Container, Segment, Grid } from 'semantic-ui-react';
import Hero from "../components/hero";
import TitleBlock from "../components/titleBlock";
import {LinkBlock} from "../components/cards";
import { useFlags } from "flagsmith/react";

const Home = () => {

    const flags = useFlags(
        ['one_click_demo', 'one_click_wic_demo', 'consensus', 'labs.demo', 'oktaprise', 'adp', 'builder',
          'learn', 'api_docs', 'status', 'usage_dashboard',
          'feature_request', 'bug_report', 'invite', 'slack']);
    

    return (<Container className="container">
          <Container className="App">
            <Hero />
            <Segment basic>
              <TitleBlock text="Solutions"></TitleBlock>
              <Grid centered stackable>
                {flags.one_click_demo.enabled &&
                  <LinkBlock
                    url={flags.one_click_demo.value}
                    text="One Click CIC"
                    label="In just one click launch a new hands on Customer Identity demo."
                    cta="Launch" />}
                {flags.one_click_wic_demo.enabled &&
                  <LinkBlock
                    url={flags.one_click_wic_demo.value}
                    text="One Click WIC"
                    label="In just one click launch a new hands on Workforce Identity demo."
                    cta="Launch"
                    focusFeature={true} />}
                {flags["labs.demo"].enabled &&
                  <LinkBlock
                    url={flags["labs.demo"].value}
                    text="Labs"
                    label="Hands on experience of Okta identity with guided labs."
                    cta="Launch" />}
                {flags.consensus.enabled &&
                  <LinkBlock
                    url={flags.consensus.value}
                    text="Digital Demos"
                    label="Delight your prospects with pre-recorded video demos of the features of the Okta Identity Cloud."
                    cta="Launch" />}
                {flags.oktaprise.enabled &&
                  <LinkBlock
                    url={flags.oktaprise.value}
                    text="Oktaprise"
                    label="Shared workforce solution demonstration environment."
                    cta="Launch" />
                }
                {flags.adp.enabled &&
                  <LinkBlock
                    url={flags.adp.value}
                    text="Portal"
                    label="Legacy CIC demonstration environment. Will be retired 1st Feb 2023."
                    cta="Launch" />
                }
                {flags.builder.enabled &&
                  <LinkBlock
                    url={flags.builder.value}
                    text="Demo Builder"
                    label="Create and manage demonstration environments for any Okta cloud."
                    cta="Launch" />
                }
              </Grid>
            </Segment>

            <Segment basic>
              <TitleBlock text="Resources"></TitleBlock>
              <Grid centered>
              {flags.invite.enabled &&
                  <LinkBlock
                    url={flags.invite.value}
                    label="Extend access for your customers from 24 hours to 30 days."
                    text="Guest Management"
                    cta="Request" 
                    target="_self"/>
                }
                {flags.learn.enabled &&
                  <LinkBlock
                    url={flags.learn.value}
                    label="Discover the features and talking points around demonstrating with demo.okta."
                    text="Learn"
                    cta="Discover" />
                }
                {flags.api_docs.enabled &&
                  <LinkBlock
                    url={flags.api_docs.value}
                    label="Use the Demo API to build a custom demo application."
                    text="API Docs"
                    cta="Discover" />
                }
                {flags.usage_dashboard.enabled &&
                  <LinkBlock
                    url={flags.usage_dashboard.value}
                    label="See the usage of demo.okta in realtime."
                    text="Demo Usage"
                    cta="Discover" />
                }
              </Grid>
            </Segment>

            <Segment basic>
              <TitleBlock text="Help"></TitleBlock>
              <Grid centered>
              {flags.status.enabled &&
                  <LinkBlock
                    url={flags.status.value}
                    label="Monitor the health of the demo.okta services."
                    text="Service Status"
                    cta="Discover" />
                }
                {flags.feature_request.enabled &&
                  <LinkBlock
                    url={flags.feature_request.value}
                    label="Submit feature ideas."
                    text="Feedback"
                    cta="Reach out" />
                }
                {flags.bug_report.enabled &&
                  <LinkBlock
                    url={flags.bug_report.value}
                    label="Help us improve."
                    text="Bug Report"
                    cta="Continue" />
                }
                {flags.slack.enabled &&
                  <LinkBlock
                    url={flags.slack.value}
                    label="Join our employee Slack channel."
                    text="#demo-okta"
                    cta="Open in Slack" />
                }
              </Grid>
            </Segment>
          </Container>

        </Container>
)
            }

export default Home
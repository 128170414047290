import React from 'react'
import './titleBlock.css'

const TitleBlock = ({text, url}) => {
    return (
        <div className="titlebox">
            {text}
        </div>
    );
};
export default TitleBlock;

import React from 'react'
import { Container, Header } from 'semantic-ui-react';
import { Button } from '@okta/odyssey-react-mui';
import { useFlags } from 'flagsmith/react';
import './hero.css'
import CopyCard from './CopyCard';


const Hero = () => {
    const flags = useFlags(['enablement_prompt']);
    const value = JSON.parse(flags.enablement_prompt.value)
    if (flags.enablement_prompt.enabled) {
        if (value.copyImage) {
            return (<CopyCard
                imageUrl={value.copyImage}
                header={value.heading}
                copy={value.subMessage}
                cta={value.cta}
                ctaAction={value.ctaAction}
                ctaSecondary={value.ctaSecondary}
                ctaSecondaryAction={value.ctaSecondaryAction}
            />)
        }
        else {
            return (
                <Container className="heroContainer">
                    <Header as='h1'>{value.heading}</Header>
                    <div class="card-content">
                        <p>{value.subMessage}</p>
                    </div>
                    <p class="link-container">
                        <a target="_blank" rel="noreferrer" href={value.ctaAction}>
                            <Button className="ui button primaryAction">{value.cta}</Button>
                        </a>
                        {value.ctaSecondaryAction && value.ctaSecondary &&
                            <a target="_blank" rel="noreferrer" href={value.ctaSecondaryAction}>
                                <Button className="ui button secondaryAction">{value.ctaSecondary}</Button>
                            </a>}
                    </p>
                </Container>
            );
        }
    }
};
export default Hero;

import { Form, Button, ToastStack, Toast, Dialog, TextField } from "@okta/odyssey-react-mui";
import { useCallback, useState } from "react";
import { addGuest } from "../../services/DemoAPI";
import { useAuth0 } from "@auth0/auth0-react";
import { ErrorMessage } from "../callouts";
import { useTranslation } from "react-i18next";
import AccessLengthSelect from "./AccessLengthSelect";
import OpportunitySelect from "./OpportunitySelect";

const ExtendGuestAccessForm = ({ extendGuests, onUpdate, onClose, open = false }) => {

  const { getAccessTokenSilently } = useAuth0();
  const { t } = useTranslation();

    const [toast, setToast] = useState(false);
    const [error, setError] = useState();
    const [opportunity_id, setOpportunity_id] = useState()

    function handleHide(event, reason){
      if (reason === 'clickaway') {
          return;
      }

      setToast(false);
  }

    const extendGuestInvite = useCallback(async ({target}) => {
      const opportunityId = opportunity_id?.target.value
      await getAccessTokenSilently()
      .then(async (accessToken) => await Promise.all(extendGuests.map((guest) => addGuest( accessToken,{name: guest.name, email: guest.email, opportunityId: opportunityId || guest.opportunityId, expiresAfter: parseInt(target.accessLength.value) || 30})))
      .then(() => onUpdate())
      .then(() => setToast(true))
      .then(() => {setOpportunity_id(null);onClose(false)})
      .catch((error) => setError(error)))
    }, [getAccessTokenSilently, onUpdate, extendGuests, onClose, opportunity_id?.target.value])

    return (<><Dialog
      PaperProps={{
          component: 'form',
      }}
      title={t('extend-guests-form-title')}
        isOpen={open}
        onClose={() => {setOpportunity_id(null);setError(null);onClose(false)}}>
    { error && <ErrorMessage error={error} /> }
    <Form name="guest-invite" onSubmit={(event) => {
          event.preventDefault();
          extendGuestInvite(event);
          setToast(true);
        }} 
        method='post'
        autoCompleteType='on'
        formActions={<>
          <Button label={t('extend-guests-submit-button', {count: extendGuests.length})} variant='primary' type='submit' />
          <Button label={t('extend-guests-cancel-button')} variant="danger" type='reset' onClick={() => {setOpportunity_id(null);setError(null);onClose(false)}} />
        </>}>
            <TextField label={t('invite-guests-email-label')} hint={t('invite-guests-email-hint')} value={extendGuests.map((guest)=>guest.email).toString().replaceAll(',',',\n')} isDisabled={true} isMultiline={extendGuests.length>1}></TextField>
            
            <OpportunitySelect opportunitySelected={setOpportunity_id}/>
            <AccessLengthSelect />
        </Form>
        </Dialog>
        <ToastStack>
        <Toast
            isVisible={toast}
            autoHideDuration={4000}
            onHide={handleHide}
            role='status'
            severity="info"
            text={t('extend-guests-acknowledgement', {count: extendGuests.length})}
        />
    </ToastStack>
    </>
    )
} 

export default ExtendGuestAccessForm
import React, { useCallback, useEffect } from "react";
import { Button, DataTable, DataTableEmptyState } from "@okta/odyssey-react-mui";
import { DeleteIcon } from "@okta/odyssey-react-mui/icons";
import { useAuth0 } from "@auth0/auth0-react";
import { removeGuest } from '../../services/DemoAPI';
import { TimeAgo } from "../../utils";
import { useOpportunityContext } from "../../context/OpportunityContext";
import { useTranslation } from "react-i18next";

const InvitedGuestDataTable = ({helpLink, guests, setGuests, getGuests, onError}) => {

  const {opportunities} = useOpportunityContext()
  const {t} = useTranslation();

  const { isAuthenticated, getAccessTokenSilently } = useAuth0();

const deleteGuests = useCallback(async (guests) => {
  await getAccessTokenSilently()
      .then((accessToken) =>
  Promise.all(guests.map((guest) => removeGuest(accessToken, guest))))
      .then(() => getGuests())
      .catch((error) => onError(error))
}, [getAccessTokenSilently, getGuests, onError])

function rowMenuItems(row) {
  return <Button variant='floating' startIcon={<DeleteIcon onClick={() => deleteGuests([row.getValue('email')])} sx={{color:'black'}} />}></Button>
}

function bulkActionMenuItems(rows) {
  const guests = Object.keys(rows);
  return [<Button variant="danger" label={t('guests-table-delete-bulk-label')} startIcon={<DeleteIcon/>} onClick={() => deleteGuests(guests)} />]
}

const getData = useCallback(({search,sort}) => {
    return filterData({ data: guests, search, sort });
  },
  [guests],
);

const filterData = ({data, ...args}) => {
  let filteredData = data;
  const { search, sort } = args;

  // Implement text-based query filtering
  if (search) {
    filteredData = filteredData.filter((row) =>
      Object.values(row).some((value) =>
        value.toString().toLowerCase().includes(search.toLowerCase()),
      ),
    );
  }

  // Implement sorting
  if (sort && sort.length > 0) {
    filteredData.sort((a, b) => {
      for (const { id, desc } of sort) {
        const aValue = a[id];
        const bValue = b[id];

        if (aValue < bValue) return desc ? 1 : -1;
        if (aValue > bValue) return desc ? -1 : 1;
      }

      return 0;
    });
  }

  return filteredData;
};

useEffect(() => {
  if (isAuthenticated) {
      getGuests()
  } else {
      setGuests([])
  }
}, [getGuests, isAuthenticated, setGuests])

  const columns = [
    {
      accessorKey: "email",
      header: t('guests-table-header-email'),
    },
    {
      Cell: ({ cell }) => opportunities?.find((op) => op.opportunity_id === cell.getValue())?.name || t("guests-table-header-opportunity-none"),
      accessorKey: "opportunityId",
      header: t('guests-table-header-opportunity'),
    },
    {
      Cell: ({ cell }) => t('guests-table-cell-access-length', {count: cell.getValue()}),
      accessorKey: "expiresAfter",
      header: t('guests-table-header-access-length'),
    },
    {
      Cell: ({ cell }) => cell.getValue() ? TimeAgo.format(new Date(cell.getValue()), 'round-minute'): '',
      accessorKey: "mustRegisterBy",
      header: t('guests-table-header-invite-expiry'),
    },
  ]
  return (
          <DataTable
            columns={columns}
            getData={getData}
            hasFilters={false}
            hasPagination={false}
            hasRowSelection
            getRowId={(row) => row.email}
            hasSearch
            hasColumnVisibility
            hasSorting={false}
            emptyPlaceholder={
              <DataTableEmptyState
                heading={t('guests-table-empty-state-heading')}
                text={<>{t('guests-table-empty-state-text')} {helpLink}</>}
              />
            }
            noResultsPlaceholder={<DataTableEmptyState
              heading={t('guests-table-no-results-heading')}
              text={t('guests-table-no-results-text')}
            />}
            rowActionButtons={rowMenuItems}
            bulkActionMenuItems={bulkActionMenuItems}
          />
  );
};
export default InvitedGuestDataTable;

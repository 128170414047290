import React from 'react'
import { useAuth0 } from "@auth0/auth0-react";
import { Link } from "react-router-dom";
import { Box, Button, Typography } from '@okta/odyssey-react-mui';
import { AppBar, Avatar, Toolbar, Container } from '@mui/material';
import './nav.css'

const Nav = () => {
    const {
      isLoading,
        isAuthenticated,
        user,
        loginWithRedirect,
        logout
      } = useAuth0();
    return (
          <AppBar position='relative'>
            <Container maxWidth='lg'>
              <Toolbar disableGutters>
                <Box sx={{ flexGrow: 1 }}>
                <Link to='/'>
                  <Box sx={{display:'flex', flexDirection:'row'}}>
                    <Avatar src="https://cdn.demo.okta.com/images/okta-aura-black.svg" />
                    <Typography variant='h2'>demo.okta</Typography>
                  </Box>
                </Link>
                </Box>
                {!isLoading && isAuthenticated && (
                  <Box sx={{display:'flex', flexDirection:'row', alignContent: 'center'}}>
                    <Button variant='secondary' onClick={() => logout({ returnTo: window.location.origin })} label='logout'/>
                      <Avatar src={user.picture} avatar sx={{marginLeft:'0.57142857rem', marginTop: '5px'}}/>
                  </Box>
                )}
                {!isLoading && !isAuthenticated && (
                  <Button onClick={loginWithRedirect} label='login' variant='primary'/>
                )}
              </Toolbar>
            </Container>
          </AppBar>
    );
};
export default Nav;

import { Form, Button, ToastStack, Toast, Dialog, Paragraph } from "@okta/odyssey-react-mui";
import { useState } from "react";
import { addGuest } from "../../services/DemoAPI";
import { useAuth0 } from "@auth0/auth0-react";
import { FileUpload } from "@okta/odyssey-react-mui/labs";
import Papa from "papaparse";
import { ErrorMessage } from "../callouts";
import { useTranslation } from "react-i18next";
import pLimit from 'p-limit';
import './bulkworkaround.css'

const BulkGuestInviteForm = ({open = false, setOpen, guests, setGuests}) => {

    const { getAccessTokenSilently } = useAuth0();
    const { t } = useTranslation();
    const limit = pLimit(2);
    const allowedExtensions = ['csv']

    const [toast, setToast] = useState(false);
    const [statusMessage, setStatusMessage] = useState()
    const [error, setError] = useState();
    const [guestData, setGuestData] = useState([]);
    const [wait, setWait] = useState(false);

    function handleHide(event, reason){
        if (reason === 'clickaway') {
            return;
        }
        setToast(false);
    }

  async function handleFileUpload(files) {
      if(files && files.length > 0) {
      const inputFile = files[0];
      const fileExtension =
                  inputFile?.type.split("/")[1];
              if (!allowedExtensions.includes(fileExtension)) {
                  setError(t('csv-file-required'));
                  return;
              }
      Papa.parse(inputFile, {
              header: true,
              delimiter: ',',
              skipEmptyLines: true,
              complete: function(results) {
                setGuestData(results.data)
              }
          });
    }
  }

  async function submitGuestInvite() {
    setError(null)
    setWait(true)

    const parsedGuests = []
    await getAccessTokenSilently()
    .then((accessToken) => 
      Promise.all(
      guestData.map((guest) => {
        const parsedGuest = { 
          name: guest.guestName || guest.guestEmail,
          email: guest.guestEmail,
          opportunityId: guest.opportunityId.length > 0 ? guest.opportunityId : undefined,
          expiresAfter: parseInt(guest.accessLength) || 30 
        };
        return limit(() => addGuest( accessToken, parsedGuest).then(parsedGuests.push(parsedGuest)))}
    ))).then(()=>{
      setToast(true)
      setStatusMessage(t('invite-guests-bulk-complete'))
      setGuests([...guests, ...parsedGuests]);
      setGuestData([])
      setOpen(false)
      setWait(false)
    })
    .catch((error) => {setGuests([...guests, ...parsedGuests]);setError(error); setWait(false)})
  }

  return (
    <>
      <Dialog
        PaperProps={{
            component: 'form',
        }}
        title={t('invite-guests-form-title')}
        isOpen={open}
        onClose={() => {setError(null); setGuestData([]); setOpen(false)}}>
          { error && <ErrorMessage error={error} /> }
          <Form name="guest-invite"
            onSubmit={(event) => {
                event.preventDefault();
                submitGuestInvite(event);
                setStatusMessage(t('invite-guests-bulk-acknowledgement', {count: guestData.length}))
                setToast(true);
              }}  
            method='post'
            autoCompleteType='on'
            formActions={
              <>
                <Button label={t('invite-guests-submit-button', {count: guestData.length})} variant='primary' type='submit' isDisabled={wait}/>
                <Button label={t('invite-guests-cancel-button')} variant="danger" type='reset' onClick={() => {setError(null); setGuestData([]); setOpen(false)}} isDisabled={wait} />
              </>}
            >
              <FileUpload id="guestupload" label={t('invite-guests-bulk-file-label')} hint={t('invite-guests-bulk-file-hint')} type='single' variant='dragAndDropWithIcon' onChange={handleFileUpload}/>
              <Paragraph>{t('invite-guests-bulk-preview', {count: guestData.length})}</Paragraph>
          </Form>
      </Dialog>
        <ToastStack>
        <Toast
            isVisible={toast}
            autoHideDuration={4000}
            onHide={handleHide}
            role='status'
            severity="info"
            text={statusMessage}
        />
    </ToastStack>
    </>
  )
} 

export default BulkGuestInviteForm
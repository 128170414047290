import { createContext, useContext, useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import axios from 'axios'
import  Config from "../Config";

const OpportunityContext = createContext({opportunities:{},setOpportunities: ()=>{}})

const OpportunityContextProvider = ({children}) => {
    const [opportunities, setOpportunities] = useState()
    const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();

    useEffect(() => {
        if (isAuthenticated) {
            async function getOpps(){
                axios.get(Config.resourceServer.demoAPI +"/opportunities",
                { 
                    headers: { Authorization: "Bearer " + await getAccessTokenSilently() }
                }
            )
            .then((response)=>
                {
                    setOpportunities(response.data) 
                })
            .catch((error)=> { console.log(error); setOpportunities([])})
            }
            if(user["https://" + process.env.REACT_APP_DOMAIN + "/connection/"] === "employee"){
                getOpps()
            }
        } else {
            setOpportunities([])
        }
    }, [isAuthenticated, getAccessTokenSilently,setOpportunities, user])

    return (
        <OpportunityContext.Provider value={{opportunities,setOpportunities}}>
            {children}
        </OpportunityContext.Provider>
    )
}
export const useOpportunityContext = ()=> useContext(OpportunityContext)
export default OpportunityContextProvider
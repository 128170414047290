import { useFlags } from "flagsmith/react";
import React, { useCallback, useState } from "react";
import { Box, BreadcrumbList, Button, Heading1, Link, Paragraph, Tabs } from "@okta/odyssey-react-mui";
import { Container } from '@mui/material';
import { useTranslation } from "react-i18next";
import { GuestManagementDrawer } from "../components/drawers";
import { listGuests } from "../services/DemoAPI";
import { InvitedGuestsTable , RegisteredGuestsTable} from "../components/tables";
import { ErrorMessage } from "../components/callouts";
import { AddIcon, RefreshIcon } from "@okta/odyssey-react-mui/icons";
import { BulkGuestInviteForm, GuestInviteForm } from "../components/forms";
import { useAuth0 } from "@auth0/auth0-react";
import { useLocation, Link as RouterLink, useNavigate } from "react-router-dom";
import { HomeIcon } from "@okta/odyssey-react-mui/icons";

function useQuery(param) {
    const { search } = useLocation();
    return React.useMemo(() => {
        const queryParams = new URLSearchParams(search);
        return {isPresent: queryParams.has(param), value: queryParams.get(param)}
    }, [search, param]);
  }

const GuestManagement = () => {

    const navigate = useNavigate();
    const {t} = useTranslation();
    const showHelp = useQuery('help');
    const [openDrawer, setOpenDrawer] = useState(showHelp.isPresent)
    const [registeredGuests, setRegisteredGuests] = useState([]);
    const [invitedGuests, setInvitedGuests] = useState([]);
    const [singleGuestFormModalOpen, setSingleGuestFormModalOpen] = useState(false)
    const [bulkGuestFormModalOpen, setBulkGuestFormModalOpen] = useState(false)
    const [error, setError] = useState();
    const { isAuthenticated, getAccessTokenSilently } = useAuth0();
    const flags = useFlags(['guest_management'])


    const getGuests = useCallback(async () => {
        setError()
        if (isAuthenticated) {
          await getAccessTokenSilently()
          .then((accessToken) =>
           listGuests(accessToken)
                .then((response) => {
                    setInvitedGuests(response.data.invited)
                    setRegisteredGuests(response.data.registered)
                }))
                .catch((error) => { setError(error); })
        } else {
            setInvitedGuests([]);
            setRegisteredGuests([]);
        }
    }, [getAccessTokenSilently, isAuthenticated, setInvitedGuests, setRegisteredGuests])

    if (flags.guest_management.enabled) {

        const singleGuestFormModal = <Button variant="primary" label={t('invite-single-guest-button')} startIcon={<AddIcon />} onClick={() => setSingleGuestFormModalOpen(true)} />
        const bulkGuestFormModal = <Button variant="primary" label={t('invite-bulk-guest-button')} startIcon={<AddIcon />} onClick={() => setBulkGuestFormModalOpen(true)} />

    return (
        <Container sx={{display: 'flex', flexGrow: '1', flexDirection:'column'}}>
            <BreadcrumbList children={[<RouterLink to='/'><HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" /></RouterLink>,<Paragraph href="">{t('guest-management-heading')}</Paragraph>]} />
            <Heading1 size="large" as="h1">{t('guest-management-heading')} <RefreshIcon onClick={() => getGuests()} /></Heading1>
            <Paragraph>{t('guest-management-description')} <Link onClick={()=> setOpenDrawer(true)}>{t('learn-more-link')}</Link></Paragraph>
            
            <GuestManagementDrawer isOpen={openDrawer} setOpen={setOpenDrawer} section={showHelp.value}/>
            <Box basic>
                { error && <ErrorMessage error={error} /> }
                <Box sx={{ justifyContent: 'flex-end', display:'flex' }}>
                    { bulkGuestFormModal}
                    {singleGuestFormModal}
                </Box>
                <>
                    <Tabs ariaLabel="basic tabs example" value="registered" tabs={[
                        {
                            children: <RegisteredGuestsTable guests={registeredGuests} setGuests={setRegisteredGuests} getGuests={getGuests} onError={setError}/>,
                            label: t('guest-management-tab-registered'),
                            value: 'registered',
                            notificationCount: registeredGuests.length
                        },
                        {
                            children: <InvitedGuestsTable guests={invitedGuests} setGuests={setInvitedGuests} getGuests={getGuests} onError={setError}/>,
                            label: t('guest-management-tab-invited'),
                            value: 'invited',
                            notificationCount: invitedGuests.length,
                        },
                    ]}/>
                    <GuestInviteForm open={singleGuestFormModalOpen} setOpen={setSingleGuestFormModalOpen} guests={invitedGuests} setGuests={setInvitedGuests} />
                    <BulkGuestInviteForm open={bulkGuestFormModalOpen} setOpen={setBulkGuestFormModalOpen} guests={invitedGuests} setGuests={setInvitedGuests} />
                </>
            </Box>
        </Container >

    )
} else {
    return(
        <Container sx={{display: 'flex', flexGrow: '1', flexDirection:'column'}}>
            <BreadcrumbList children={[<RouterLink to='/'><HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" /></RouterLink>,<Paragraph href="">{t('guest-management-heading')}</Paragraph>]} />
            <Heading1 size="large" as="h1">{t('guest-management-heading')} </Heading1>
            <Paragraph>{t('error-callout-title')} </Paragraph>
            <Link onClick={()=> navigate('/')}>{t('returnHome')}</Link>
        </Container>
    )
}
}

export default GuestManagement;
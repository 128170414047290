import React from 'react'
import './linkBlock.css'
import { Typography } from '@okta/odyssey-react-mui';
import { Link as RouterLink } from 'react-router-dom';


const LinkBlock = ({text, url, label, cta, focusFeature, target="_blank"}) => {
    return (
        <RouterLink to={url}>
        <div className={focusFeature? "focusFeature card":"card"}>
                <div class="card-content">
                  <Typography variant='h4'>{text}</Typography>
                  {label &&
                <Typography variant='support'>{label}</Typography>
            }
                </div>
                <p className="link-container">{cta}</p>
            </div>
        </RouterLink>
    );
};
export default LinkBlock;

import React, {useState} from "react";
import { useOpportunityContext } from "../../context/OpportunityContext"
import { Autocomplete, Fieldset } from "@okta/odyssey-react-mui";
import { useTranslation } from "react-i18next";
//workaround for autocomplete layout
import './OpportunitySelect.css'

const OpportunitySelect = ({allowEdit = true, opportunitySelected, error}) => {

    const {opportunities} = useOpportunityContext()
    const {t} = useTranslation();
    const [selected, setSelected] = useState(null)

    function buildOptions(opportunities){
        const options = []
        opportunities?.forEach(element => {
            options.push(
                {
                    id: element.name,
                    label: element.name,
                    value: element.opportunity_id
                }
            )
        });
        return options
    }

    function onChange(event,arg) {
        const {label = null, value = null} = arg || {};
        if(label && value){
            setSelected({label:label,value})
            opportunitySelected({
                target:{
                    id: 'opportunity',
                    name: 'opportunity',
                    value: value,
                    label: label
                }
            })
        } else {
            setSelected(null)
            opportunitySelected({
                target:{
                    id: 'opportunity',
                    name: 'opportunity',
                    value: null,
                    label: null
                }
            })
        }
    }

    return (
        <Fieldset>
        <Autocomplete
            label={t('opportunity-select-label')}
            hint={t('opportunity-select-hint')}
            options={buildOptions(opportunities)}
            isDisabled={!allowEdit}
            onChange={onChange}
            value={selected}
            getIsOptionEqualToValue={(option, value) => option.value === value.value}
            errorMessage={error}
            name='opportunitySelect'
            isOptional
        />
        </Fieldset>
    )
}
export default OpportunitySelect
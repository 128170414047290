import { useAuth0 } from "@auth0/auth0-react";
import { Dimmer, Loader} from 'semantic-ui-react';
import './App.css';
import Nav from "./components/nav";
import flagsmith from 'flagsmith';
import StatusBanner from "./components/StatusBanner";
import { OpportunityContextProvider } from "./context";
import { Route, Routes } from 'react-router-dom';
import {GuestManagement, Home } from "./routes";
import Footer from "./components/footer";
import { Box } from "@okta/odyssey-react-mui";

function App() {
  const {
    isLoading,
    isAuthenticated,
    user,
    error,
    loginWithRedirect,
  } = useAuth0();

  if (isLoading) {
    return (
      <Dimmer blurring inverted active id="innerDimmer">
        <Loader inverted>Loading</Loader>
      </Dimmer>
    );
  }
  if (error) {
    window.location.replace(`${process.env.REACT_APP_ERROR_URL}?client_id=${process.env.REACT_APP_CLIENTID}&error=${error.name}&error_description=${error.message}`);
    return null
  }
  if (isAuthenticated) {
    flagsmith.identify(user.email, { connection: user["https://" + process.env.REACT_APP_DOMAIN + "/connection/"] });
    return (
      <Box sx={{minHeight:'100vh', display: 'flex', flexDirection: 'column'}}>
        <StatusBanner />
        <Nav />
        <OpportunityContextProvider>
          <Box sx={{display:'flex', flexGrow: '1'}}>
              <Routes>
                <Route path='/' exact element={<Home />} />
                <Route path='/guests' exact element={<GuestManagement />} />
              </Routes>
          </Box>
        </OpportunityContextProvider>
        <Footer/>
      </Box>
    );
  } else {
    console.log(window.location.search)
    return loginWithRedirect({
      appState: {
        returnTo: window.location.pathname+window.location.search
      }});
  }
}

export default App;

import { Accordion, Paragraph } from "@okta/odyssey-react-mui";
import { Drawer } from "@okta/odyssey-react-mui/labs";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from 'react-router-dom';

const GuestManagementDrawer = ({isOpen = false, setOpen, section}) => {

    const {t} = useTranslation();

    return <Drawer
      ariaLabel="close"
      title={t('guest-management-help-title')}
      isOpen={isOpen}
      onClose={() => setOpen(false)}
      variant="temporary"
    >
      <React.Fragment key=".0">
        <Accordion isDefaultExpanded={!section} label={t('guest-management-help-section-overview')}>
            <Paragraph>
                External Guest Access to demo.okta is controlled solely through an Okta Employee invitation only process. This allows us to meet security and legal requirements as well as ensuring that those allowed to access demo.okta resources, create demonstrations of any Okta Identity Cloud, and get hands on with Okta products are accountable to an Okta Employee. When an invite is created the user will be able to sign up to demo.okta and access will be granted for the defined period. Should a guest access expire while they still require access then the access can be extended by inviting them again. The next time they sign in their access will update and extend to what the inviter defined. An invite should ideally be tied to an active opportunity; however we understand that it may not always be possible. Access can be requested from between 7 and 120 days. 
            </Paragraph>
            <Paragraph>
                Invitations that have not been accepted by guests you have invited are shown in the data table which can be searched. If an invite is no longer required it should be deleted using the button on the row or by selecting invites to delete and selecting the 'Delete Selection Button'.
            </Paragraph>
        </Accordion>
        <Accordion isExpanded={!!section && section === 'invite' ? true : undefined} label={t('guest-management-help-section-invite')}>
            <Paragraph>
                Inviting a single guest is simple. Click 'Invite a user' and a modal form will appear. Input the full name, email address being used to access demo.okta and select the opportunity and access length and click submit. This will create the invite and populate the entry into the table
            </Paragraph>
        </Accordion>
        <Accordion isExpanded={!!section && section === 'bulk'? true : undefined} label={t('guest-management-help-section-bulk')}>
            <Paragraph>
                From time to time it may be required to invite a number of guests to use demo.okta. To facilitate this there is a bulk invitation flow is provided through a CSV file upload. The CSV must contain a header of 'guestName', 'guestEmail', 'opportunityId', 'accessLength'. A template file containing the headers can be found <RouterLink to='https://cdn.demo.okta.com/bulk-invite-template.csv'>here</RouterLink> Each row should contain a minimum of the full guest name and the email address they intend to use to access demo.okta. Access length should be a value between 7 and 120 but will default to 30 if not supplied. OpportunityId should be the SFDC opportunity identifer if available but is not required. Once saved click 'Bulk Invite' and add the CSV file. The form will show how many guests will be invited once the file has been uploaded. Click 'Invite Guests' to finish adding the guests.
            </Paragraph>
        </Accordion>
      </React.Fragment>
    </Drawer>

}

export default GuestManagementDrawer;
import { Button, Callout } from '@okta/odyssey-react-mui';
import { RefreshIcon } from '@okta/odyssey-react-mui/icons';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, List } from 'semantic-ui-react';

const ErrorMessage = ({error, retryAction}) => {
    const validationErrors = error.response?.data?.validationErrors;
    const { t } = useTranslation();

    function displayValidationError(error){
        return (<List.Item>{error.message}</List.Item>)
    }

    let displayMsg = error
    if(error.response?.data?.message){
        displayMsg = error.response?.data?.message
    } else if(error.message){
        displayMsg = error.message
    }
    return (
        <Callout severity='warning' title={t('error-callout-title')}>
            <Fragment>
                <p>{displayMsg}</p>
                {(validationErrors?.length>0) &&
                    <Container className="scrollable">
                        <List bulleted>
                        {validationErrors.map((error) =>
                            displayValidationError(error)
                        )}
                        </List>
                    </Container>
                }
                <p>{t('error-callout-persistent')}</p>
                {retryAction ?
                    (<Button variant='primary' onClick={retryAction} startIcon={<RefreshIcon/>} label={t('retry-label')}/>) : null}
            </Fragment>
        </Callout>
    )
}

export default ErrorMessage
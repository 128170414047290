import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import { Auth0Provider } from "@auth0/auth0-react";
import flagsmith from "flagsmith";
import { FlagsmithProvider } from 'flagsmith/react';
import { OdysseyProvider } from '@okta/odyssey-react-mui';
import { BrowserRouter, useNavigate } from 'react-router-dom';
import config from './Config';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';

function Auth0ProviderWithRedirectCallback({ children, ...props }) {
  const navigate = useNavigate();
  const onRedirectCallback = (appState) => {
    navigate((appState?.returnTo) || window.location.pathname);
  };

  return (
    <Auth0Provider onRedirectCallback={onRedirectCallback} cacheLocation="localstorage" {...props}>
      {children}
    </Auth0Provider>
  );
}

const container = document.getElementById('app');
const root = createRoot(container);
root.render(
  <BrowserRouter>
  <OdysseyProvider>
    <I18nextProvider i18n={i18n}>
      <Auth0ProviderWithRedirectCallback
        domain={process.env.REACT_APP_DOMAIN}
        clientId={process.env.REACT_APP_CLIENTID}
        redirectUri={window.location.origin}
        audience={config.oidc.audience}
        scope={config.oidc.scopes}
      >
        <FlagsmithProvider
          options={{
            environmentID: process.env.REACT_APP_FLAGSMITH_ENV,
            enableAnalytics: true,
            defaultFlags: {
              "labs.demo": {
                enabled: true,
                value: "https://labs.demo.okta.com"
              },
              builder: {
                enabled: true,
                value: "https://builder.demo.okta.com"
              },
              learn: {
                enabled: true,
                value: "https://learn.demo.okta.com"
              },
              status: {
                enabled: true,
                value: "https://status.demo.okta.com",
              },
              bug_report: {
                enabled: true,
                value: "https://forms.gle/m5kKXvAxTbESwJ5L8"
              }
            }
          }}
          flagsmith={flagsmith}>
          <App />

        </FlagsmithProvider>
      </Auth0ProviderWithRedirectCallback>
    </I18nextProvider>
  </OdysseyProvider>
  </BrowserRouter>
);
//Environment specific
const CLIENT_ID = process.env.REACT_APP_CLIENTID;
const DOMAIN = process.env.REACT_APP_DOMAIN;
const DEMO_API_ENDPOINT = process.env.REACT_APP_DEMOAPI_ENDPOINT
const AUDIENCE = process.env.REACT_APP_AUDIENCE

//Application vars
const REDIRECT_URI = `${window.location.origin}`;
const SCOPE = "opportunity:read guest:read guest:write guest:delete"


var config = {
  oidc: {
    clientId: CLIENT_ID,
    issuer: DOMAIN,
    redirectUri: REDIRECT_URI,
    scopes: SCOPE,
    audience: AUDIENCE
  },
  resourceServer: {
    demoAPI: DEMO_API_ENDPOINT,
  },
};
export default config
import { Banner } from '@okta/odyssey-react-mui'
import axios from 'axios'
import React, { useEffect, useState } from 'react'


export default function StatusBanner() {
    const [status, setStatus] = useState()

    useEffect(() => {
        axios.get(process.env.REACT_APP_SERVICE_MONITOR).then((status) => {
            setStatus(status.data.result.status_overall)
        }
        )
    }, [])

    if (status && status?.status_code !== 100) {
        return (
            <Banner severity={status.status_code >= 400 ? 'error' : 'warning'} text={status.status} linkUrl='https://status.demo.okta.com' linkText='See service status'></Banner>
        )
    }
}